import useDocumentTitle from '../../features/pageTitle/pageTitle';
import TripleSlideshow from '../../features/triple-slideshow';
import './styles.scss';

const Triple = () => {
  useDocumentTitle('Triple');

  return (
    <main className="triple-page">
      <TripleSlideshow />
    </main>
  );
};

export default Triple;
