import React, { useState, useEffect } from 'react';
import './styles.scss';

const TripleSlideshow: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState(0); // Active image index
  const [activeFade, setActiveFade] = useState('in'); // Manage fade direction

  useEffect(() => {
    // Fetch and set images from manifest
    fetch('/assets-slideshow/manifest.json')
      .then((response) => response.json())
      .then((data) => {
        const paths = data.images.map(
          (imageName: string) => `/assets-slideshow/${imageName}`
        );
        setImages(paths);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFade('out'); // Start by fading out the current image

      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length); // Move to next image
        setActiveFade('in'); // Fade in the next image
      }, 0); // This timeout should match the CSS transition duration
    }, 3000); // Interval for changing images

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slideshow-wrapper">
      {images.map((img, index) => (
        <img
          key={index}
          src={img}
          alt={`Slideshow ${index}`}
          className={`slideshow-image ${activeIndex === index ? `fade-${activeFade}` : ''}`}
          style={{ zIndex: activeIndex === index ? 2 : 1 }} // Ensure active image is on top
        />
      ))}
    </div>
  );
};

export default TripleSlideshow;
