import React, { useState, useEffect } from 'react';
import './styles.scss';

const TripleSlideshow: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  // Tracks visibility of each image
  const [visibleImages, setVisibleImages] = useState<Set<number>>(
    new Set([0, 1, 2])
  );

  useEffect(() => {
    fetch('/assets-slideshow/manifest.json')
      .then((response) => response.json())
      .then((data) => {
        const paths: string[] = data.images.map(
          (imageName: string) => `/assets-slideshow/${imageName}`
        );
        setImages(paths);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleImages((prevVisible) => {
        const maxIndex = images.length;
        const nextVisible = new Set<number>();
        prevVisible.forEach((index) => {
          const nextIndex = (index + 3) % maxIndex;
          nextVisible.add(nextIndex);
        });
        return nextVisible;
      });
    }, 3000); // Change images every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slideshow-wrapper2">
      {images.map((img, index) => (
        <div
          key={index}
          className="image-slot2"
          style={{
            opacity: visibleImages.has(index) ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            zIndex: visibleImages.has(index) ? 2 : 1,
          }}
        >
          <img src={img} alt={`Slide ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default TripleSlideshow;
