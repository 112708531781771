import useDocumentTitle from '../../features/pageTitle/pageTitle';
import './styles.scss';

function LostPage() {
  useDocumentTitle('You lost?');

  return <main className="lost-page">404 Rip</main>;
}

export default LostPage;
