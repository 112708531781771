import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../home';
import LostPage from '../lost-page';
import './styles.scss';
import Triple from '../triple';

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/triple" element={<Triple />} />
        <Route path="*" element={<LostPage />} />
      </Routes>
    </Router>
  );
};

export default Root;
