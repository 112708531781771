import useDocumentTitle from '../../features/pageTitle/pageTitle';
import Slideshow from '../../features/slideshow';
import './styles.scss';

const Root = () => {
  useDocumentTitle('Home');

  return (
    <main className="home-page">
      <Slideshow />
    </main>
  );
};

export default Root;
